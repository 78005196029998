import React from 'react';
import NextImage from 'next/image';
import { S3_OEM_LOGO_LINK } from '@/lib/constants';
import { MakeIconProps } from '@/types';
import clsx from 'clsx';

export const MakeIcon: React.FC<MakeIconProps> = ({
  make,
  className = '',
  width = 16,
  height = 16,
  'aria-label': ariaLabel,
  withOverlay = false,
}: MakeIconProps) => {
  const [error, setError] = React.useState(false);

  const handleImageError = () => {
    setError(true);
  };

  if (error) {
    return null;
  }

  const icon = (
    <div
      className={
        withOverlay
          ? 'relative z-10 flex aspect-square items-center justify-center rounded-full bg-neutral-100 p-l shadow-s group-hover:shadow-l'
          : className
      }
    >
      <NextImage
        aria-label={ariaLabel || `${make} icon`}
        src={`${S3_OEM_LOGO_LINK}/${make}.png`}
        alt={`${make} icon`}
        onError={handleImageError}
        width={width}
        height={height}
      />
    </div>
  );

  return withOverlay ? (
    <div className="group relative flex justify-center">
      <div className="relative flex h-full w-full items-center justify-center rounded-full">
        {icon}
        <div
          className={clsx(
            'gradient absolute z-0 m-m size-[112px] rounded-full bg-neutral-300 bg-gradient-to-b from-[rgba(255,255,255,.7)] to-[rgba(255,255,255,.1)] opacity-0 transition-opacity duration-250 group-hover:opacity-100',
            {
              '!bg-[#DF3339]': make === 'Tesla',
              '!bg-[#102B72]': make === 'Ford' || make === 'Hyundai',
              '!bg-[#E5B60F]': make === 'Chevrolet' || make === 'Rivian',
              '!bg-[#080812]': make === 'Kia',
            }
          )}
        />
      </div>
    </div>
  ) : (
    icon
  );
};
